import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path1/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "stateselector"
    }}>{`StateSelector`}</h1>
    <p>{`When sharing `}<strong parentName="p">{`EffectModule`}</strong>{` cross muti components, select a partial `}<strong parentName="p">{`state`}</strong>{` from the whole `}<strong parentName="p">{`AppState`}</strong>{` in `}<strong parentName="p">{`EffectModule`}</strong>{` to avoid useless rendering is a common requirement.
`}<strong parentName="p">{`Sigi`}</strong>{` provide `}<strong parentName="p">{`selector`}</strong>{` API in `}<inlineCode parentName="p">{`useModule`}</inlineCode>{` hook and `}<inlineCode parentName="p">{`useModuleState`}</inlineCode>{` hook to meet this requirement. Component can select the states they care about through this API, and every other parts of the `}<strong parentName="p">{`AppState`}</strong>{` changes would be ignored and won't trigger rerender.`}</p>
    <iframe src="https://codesandbox.io/embed/sigi-recipes-state-selector-m4m18?expanddevtools=1&fontsize=14&hidenavigation=1&module=%2Fsrc%2Findex.tsx&theme=dark" style={{
      "width": "100%",
      "height": "500px",
      "border": "0",
      "borderRadius": "4px",
      "overflow": "hidden"
    }} title="sigi-recipes-state-selector" allow="geolocation; microphone; camera; midi; vr; accelerometer; gyroscope; payment; ambient-light-sensor; encrypted-media; usb" sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin"></iframe>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      